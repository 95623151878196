:root {
  --white: #fff;
  --themeColor: #01b763;
  --toggleBg: #fff7e7;
}

.route-planner--modal.mobile {
  width: 350px;
}

.route-planner--modal
  div[aria-label="Starting Location"]
  .route-planner--modal
  div[aria-label="Destination Location"] {
  margin-top: 22px !important;
}

.route-planner--modal {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.highres.start {
  background-image: url(../../image/start_location_grey800_18dp.png);
}

.highres.vert {
  background-image: url(../../image/route_3dots_grey650_24dp.png);
}

.highres.dest {
  background-image: url(../../image/place_outline_red600_18dp.png);
}

.highres.start,
.highres.vert,
.highres.dest {
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position-x: 3px;
}

.highres.start {
  left: 12px;
  top: 80px;
  background-size: 18px 18px;
}

.highres.vert {
  top: 104px;
  left: 9px;
  background-size: 24px 24px;
}

.highres.dest {
  top: 135px;
  left: 12px;
  background-size: 18px 18px;
}

.route-planner--modal-content {
  width: 350px;
  margin: 20px auto;
  margin-bottom: 0;
}
