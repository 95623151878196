.facebook-button{
    width: 100%;
    height: 50px;
    font-size: 18px!important;
    text-transform: uppercase!important;
    padding: 11px 0;
    border-radius: 40px!important;
    letter-spacing: .3px;
    margin-bottom: 0!important;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
}

.google-button{
    width: 100%;
    height: 50px;
    font-size: 18px!important;
    text-transform: uppercase!important;
    padding: 11px 0;
    border-radius: 40px!important;
    letter-spacing: .3px;
    margin-bottom: 0!important;
    transition: background-color .3s,border-color .3s;
    background-color: #dd4b39 !important;
    border: calc(0.06887vw + 0.67769px) solid #dd4b39;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-family: Helvetica,sans-serif;
    font-weight: 700 !important;
    -webkit-font-smoothing: antialiased;
}