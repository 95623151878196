.search-bar{
   position: absolute;
}

#filter-popover .MuiPopover-paper{
   max-height: calc(100% - 152px);
}

#filter-popover .MuiList-root{
   margin-top: -28px;
}

#filter-popover .MuiAccordionDetails-root{
   padding: 8px 0 0 0;
}

@media screen and (max-width: 420px) {
   .Map-toolbar {
      gap: 0
   }

   .Map-toolbar 
   .search-bar-location 
   form{
      width: '100%';
   }
}