.cluster {
  width: 30px !important;
  height: 30px !important;
}
.cluster img {
  width: 100%;
  height: 100%;
}
.cluster div {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  color: var(--app-color) !important;
}
