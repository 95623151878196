footer#pt-footer {
  background: url(https://chargemiles.ca/wp-content/uploads/2021/09/bg-2.jpg);
  background-position: center top;
  background-size: cover;
}

footer#pt-footer .footerPrimary {
  background: rgba(44, 44, 44, 0.9);
}

.pt-subscribe {
  background: #01b763;
}
.pt-subscribe-contact {
  padding: 0 5px;
  height: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pt-subscribe-contact img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  height: 100px;
  margin: auto;
  opacity: 0.1;
}
.pt-subscribe-contact span {
  font-family: "Futura";
  font-size: 18px;
  line-height: 26px;
  display: block;
  color: #ffffff;
  font-weight: 500;
}
.pt-subscribe-contact a {
  outline: none !important;
  text-decoration: none;
}

footer#pt-footer .firstCol {
  display: flex;
  color: white;
  align-items: center;
  font-size: 32px;
}

footer#pt-footer .lastCol {
  display: flex;
  color: white;
  align-items: center;
  font-size: 32px;
  flex-direction: row-reverse;
}

.footerCard {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.footerCard .pt-footer-logo {
  height: 60px;
  margin-bottom: 15px;
  width: 170px;
}
.footerCard a {
  justify-content: left;
  color: white;
  text-transform: none;
}
.footerCard a span {
  text-align: left;
  color: #01b763;
}
footer#pt-footer .footerCard .footer-title {
  margin-bottom: 15px;
  padding: 0;
  font-size: 28px;
  position: relative;
  line-height: 36px;
}
footer#pt-footer .footerCard form {
  text-align: center;
}
footer#pt-footer .footerCard form input[type="text"] {
  background: #f7f9fa;
  text-align: center;
  border: 1px solid #f7f9fa;
  color: #2c2c2c;
  width: 100%;
  float: left;
  font-size: 16px;
  padding: 0 15px 0 15px;
  height: 54px;
  line-height: 54px;
  outline: none;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

footer#pt-footer .footerCard form input[type="submit"] {
  text-align: center;
  margin-top: 15px;
  background: #01b763;
  padding: 6px 30px;
  border: none;
  border-radius: 5px;
  color: white;
  line-height: 2;
}
.footer-title:before {
  padding: 0;
  margin: 0;
  display: inline-block;
  content: "";
  width: 45px;
  height: 2px;
  background-color: #01b763;
  position: absolute;
  left: 0;
  bottom: -1px;
}

.subscribe {
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  background: #2d2d2d;
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 100%;
}

.subscribe img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  object-fit: scale-down;
  z-index: -1;
  vertical-align: middle;
}
