/* Chargemiles common utilities styles */
.absolute{
    position: absolute;
}

.relative{
    position: relative;
}

.round{
    border-radius: 50px !important;
}

.text-center{
    text-align: center !important;
}

.float-left{
    float: left !important;
}

.float-right {
    float: right !important
}

.text-justify{
    text-align: justify !important;
}

.flex-col {
    flex-direction: column !important;
}

/* Vertical Alignment */
.align-middle {
    vertical-align: middle;
}

/* Display */
.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.flex {
    display: flex !important;
}

/* Margins */
.ml-1 {
    margin-left: 4px !important;
}

.ml-2 {
    margin-left: 8px !important;
}

.mb-28 {
    margin-bottom: 46px !important
}

.my-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.my-3 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

/* padding */
.p-2 {
    padding: 8px !important;
}

.p-3 {
    padding: 16px !important
}

.pl-1 {
    padding-left: 4px !important;
}

.pl-2 {
    padding-left: 8px !important;
}

.pl-3 {
    padding-left: 16px !important
}

.pr-1 {
    padding-right: 4px !important;
}

.pr-2 {
    padding-right: 8px !important;
}

.pr-3 {
    padding-right: 16px !important
}

.py-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.py-3 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.px-2 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.px-3 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

/* Font-Weight */
.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}