.MuiPaper-root{
   left: 0px;
}

div[aria-labelledby="scroll-dialog-Location Filters"]{
   border-radius: 20px;
}

.location-filter--socket-types,
.location-filter--speed,
.location-filter--network
{
   display: grid;
   grid-template-columns: repeat(5, minmax(0, 1fr));
   column-gap: 1rem;
   row-gap: 1rem;
}

.location-filter--socket-types.mobile,
.location-filter--speed.mobile,
.location-filter--network.mobile{
   grid-template-columns: repeat(4, minmax(0, 1fr));
   row-gap: 1rem;
}

.socket button,
.speed button,
.network button{
   border-radius: 10px;
   background-color: #f1f2fe;
   border: 2px solid #d2dde1;
   transition: border-color 0.2s ease-in;
}

.socket button:hover,
.speed button:hover,
.network button:hover{
   border-color: var(--themeColor);
}

.socket button.Mui-selected.socket-toggle,
.speed button.Mui-selected.socket-toggle,
.network button.Mui-selected.network-toggle
{
   background-color: var(--toggleBg);
   border-color: var(--themeColor);
}

.location-filter--filters:not(:first-child){
   margin-top: 1.25rem;
}

.saved-trip-panel ul{
   margin-left: -16px;
   margin-right: -16px;
}

.saved-trip-panel ul > li {
   cursor: pointer;
   padding-left: 16px;
   transition: background-color 0.5s ease-in;
}

.saved-trip-panel ul > li:hover {
   background-color: rgba(0, 0, 0, 0.04);
}

.location-filter-socket-type-icon svg{
   width: 48px;
   height: 38px;
}