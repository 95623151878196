.pt-breadcrumb {
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-position: center bottom;
  background-image: url(https://chargemiles.ca/wp-content/uploads/2021/09/bg-2.jpg);
  padding: 90px 0;
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.pt-breadcrumb:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.9);
  color: #ffffff;
  opacity: 0.2;
}

.breadcrumb .active {
  color: #01b763;
}
.breadcrumb .active:before {
  content: ">";
  font-size: 16px;
  font-family: "Ionicons";
  padding-right: 12px;
  color: white;
  font-weight: 700;
}

.pt-breadcrumb-container .breadcrumb li {
  list-style-type: none;
  margin-right: 12px;
  padding-left: 0;
}

.pt-breadcrumb-container .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.pt-breadcrumb-container .breadcrumb li a {
  text-decoration: none;
  color: #ffffff;
}

.pt-breadcrumb-container .breadcrumb li a:hover {
  text-decoration: none;
  color: #01b763;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #ffffff;
  content: var(--bs-breadcrumb-divider, ">");
}
