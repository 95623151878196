.popover-menu {
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

header .cm-contact {
    display: inline-flex;
    align-items: center;
}

.nav > .nav-item {
    display: inline-block;
}

.nav > .nav-item button {
    margin-top: 16px;
    margin-bottom: 16px;
}

.nav .nav-item.active {
    border-bottom: 3px solid white;
}