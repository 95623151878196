/* Chargemiles variable css styles */

:root {
  --app-color: #01b763;
  --app-color-dark: #5ca107;
  --app-base: #ffff;
  --text-opacity: 1;
  --text-secondary: rgb(103 128 159 / var(--text-opacity));
  --yellow-800: #e7d219;
  --app-color-light: #01b76366;
  --google-font: "Work Sans", sans-serif;
  --demo-blue: rgba(175, 195, 202, 0.4);
  --header-height: 68px;
  --header-height-tablet: 64px;
  --header-height-mobile: 56px;
}
