/* Chargemiles common color  */

/* Background colors */
.bg-primary {
    background-color: var(--app-color) !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-grey-300 {
    background-color: #c7c7c7 !important;
}

/* Text Colors */
.text-white {
    color: #fff !important;
}

.text-primary {
    color: var(--app-color) !important;
}
